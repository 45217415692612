export const environment = {
  production: true,

  version: '2024.7.0',
  versionHash: '119598ac8cce65c7514e61e6ef6c2c4f1cd2e77c',

  api: {
    basePath: 'https://api.sugardaddy.jp',
  },

  stream: {
    basePath: 'wss://api.sugardaddy.jp',
  },

  cms: {
    basePath: 'https://help.sugardaddy.jp',
  },

  site: {
    noindex: 'no',
  },

  payment: {
    usePaymentServiceMock: false,
    paidy: {
      apiKey: 'pk_live_ab78ub4d4plpc37pd8u4ulbe8j',
    }
  },

  amplify: {
    Auth: {
      identityPoolId: '',
      region: 'ap-northeast-1',
      userPoolId: 'ap-northeast-1_4Fc8fqTnC',
      userPoolWebClientId: '5311hu64deg647pfo4kr461jdq',
    },
  },

  recatpcha: {
    siteKey: '6LcAuVojAAAAANRkBpGBKQJecP7EIjtPMeMuR0sb',
  },

  analysis: {
    gtagId: 'UA-59924480-1',
    gtagId2: 'GTM-5Z22HQR',
    gtm_auth: 'Gop3iRR4BxAC7HWK84neWg',
    gtm_preview: 'env-1',
    gtm_resource_path: '',
    pixelId: '1976549862601304',
    uwtId: 'o6019',
  },
};

export const ENV_MODULES: any[] = [];

export const ENV_PROVIDERS: any[] = [];
